.lg {
  height: 1.125rem;
  width: 1.125rem;
  bottom: 0.65rem;
  left: 0.65rem;
  border-radius: 50%;
}
.md {
  height: .925rem;
  width: .925rem;
  bottom: 0.45rem;
  left: 0.45rem;
  border-radius: 50%;
}
.sm {
  height: 0.725rem;
  width: 0.725rem;
  bottom: 0.2rem;
  left: 0.2rem;
  border-radius: 50%;
}
.xs {
  height: .625rem;
  width: .625rem;
  bottom: 0.05rem;
  left: 0.05rem;
  border-radius: 50%;
}


.status-online{
  background-color: #28a745;
}

.status-offline{
  background-color: #adb5bd;
}

